@import "@scss/theme";

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.row.form-row {
  min-height: 37px;

  & > div[class^="col"] {
    word-break: break-word;
  }
}

fieldset:disabled {
  .combo-box,
  .input-wrapper {
    border-bottom: 1px solid transparent !important;
    box-shadow: 0 0 0;

    textarea,
    input {
      padding: 0;
    }

    // hides empty text area in readonly mode
    textarea[data-value=""] {
      display: none;
    }
  }

  .combo-box__placeholder {
    display: none;
  }
  .combo-box__opener {
    button {
      padding-left: 0;
      padding-right: 0;
    }
    &:after {
      content: none;
    }
  }

  .date-picker {
    .date-picker__search {
      display: none;
    }
    
    .react-datepicker-popper {
      display: none;
    }
  }
}
