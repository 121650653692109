@import "@scss/theme";

$datepicker__background-color: $color-gray-5 !default;
$datepicker__border-color: $color-gray-2 !default;
$datepicker__highlighted-color: $color-orange !default;
$datepicker__muted-color: $default-font-color !default;
$datepicker__selected-color: $color-accent-5 !default;
$datepicker__text-color: $default-font-color !default;
$datepicker__header-color: $default-font-color !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", helvetica, arial, sans-serif !default;
$datepicker__item-size: 2rem !default;
$datepicker__margin: 1rem !default;
$datepicker__navigation-button-size: 35px !default;
$datepicker__triangle-size: 8px !default;

@import "react-datepicker/src/stylesheets/datepicker.scss";

.date-picker {
    box-shadow: $box-shadow-s;
    background-color: white;
    z-index:2;
    
    .react-datepicker__navigation {
        top: 20px;
    }

    .react-datepicker__navigation-icon {
        &:before {
            border-width: 1.5px 1.5px 0 0;
        }
    }

    .react-datepicker__current-month {
        position: relative;
        padding: 15px 0;
    }

    input {
        color: $default-font-color;
        padding-right: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden
    }

    &__search {
        pointer-events: none;
        color: transparent;
        border: 0;
        background: transparent;
        background-image: url(./img/icon-date.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        position: absolute;
        right: 0;
        top: -1px;
        bottom: 0;
        width: 35px;
        overflow: hidden;
    }

    &__clear {
        cursor: pointer;
        color: transparent;
        border: 0;
        background: transparent;
        background-image: url(./img/icon-clear.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        position: absolute;
        right: 25px;
        top: 1px;
        bottom: 0;
        width: 35px;
        overflow: hidden;
    }

    &__clearable {
        input {
            color: $default-font-color;
            padding-right: 52px;
        }
    }
}
