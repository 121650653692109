@import "@scss/theme";

@keyframes animNotificationAppear {
  0% {
    z-index: -1;
    top: -10px;
    opacity: 0;
    max-height: 0;
  }
  100% {
    top: 0;
    opacity: 1;
    max-height: 100px;
  }
}

@keyframes animNotificationDismiss {
  0% {
    opacity: 1;
    max-height: 100px;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}

.notification {
  animation: animNotificationAppear 0.15s forwards;
  &--dismissed {
    animation: animNotificationDismiss 0.15s forwards;
  }

  pointer-events:all;
  overflow: hidden;
  position: relative;
  display: block;
  float: left;
  clear: both;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  margin-bottom: 0;
  box-shadow: $box-shadow-notification;
  background: white;
  color: $default-font-color;

  &__icon {
    display: inline-block;
    position: relative;
    top: 0;
    background-image: url(./img/icon-info.svg);
    background-repeat: no-repeat;
    margin: 2px 15px 0 0;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }

  .notification__container {
    border-style: solid;
    border-color: $color-accent-5;
    border-width: 4px 0 0 0;
  }

  &--warning {
    .notification__container {
      border-color: $color-orange;
      .notification__icon {
        background-image: url(./img/icon-warning.svg);
      }
    }
  }

  &__text {
    display: inline-block;
    position: relative;
    flex: 1;
  }

  &__close {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    background-image: url(./img/b-close.svg);
    background-repeat: no-repeat;
    margin: 2px 0 0 15px;
    background-size: 16px;
    width: 16px;
    height: 16px;
    border: 0;
    padding: 0;
    overflow: hidden;
    color: transparent;
  }

  &__container {
    padding: 10px 15px 14px 15px;
    display: flex;
    flex-direction: row;
  }
}
