@import "@scss/theme";

.workflow {
    white-space: nowrap;
    width: 100%;
    min-height: 300px;
    position: relative;
    margin-bottom: $margin-l;

    &--sticky-header {
        thead {
            position: sticky;
            top: 80px;
            z-index: 1;
            background: white;
        }
    }

    /**
    * table styles
    */
    table {
        padding: 0;
        margin: 0;
        width: 100%;
        border-spacing: 0;

        tr {
            cursor: pointer;
            position: relative;
            &:hover,
            &.workflow__row--checked {
                td {
                    background-color: $color-gray-6;
                }
            }

            &.workflow__item--expanded {
                td {
                    > div.workflow__item--dropdown-icon {
                        &:after {
                            top: 20px;
                            transform: rotate(-135deg);
                            -webkit-transform: rotate(-135deg);
                        }
                    }
                }
            }

            &.workflow__item--clickable {
                td:last-child::after {
                    content: "";
                    display: block;
                    height: 10px;
                    width: 10px;
                    top: 2px;
                    position: relative;
                    border: solid $default-font-color;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }

                &:hover {
                    td {
                        border-bottom: 1px solid $color-orange;
                    }
                    td:last-child::after {
                        border: solid $color-orange;
                        border-width: 0 1px 1px 0;
                    }
                }
            }
            &.workflow__totals {
                cursor: default;

                td {
                    padding-top: 35px;
                    border-spacing: 0 1em;
                }
            }
        }

        td,
        th {
            margin: 0;
            padding: 12px 20px 12px 0;
            text-align: left;
            vertical-align: top;
            border-bottom: 1px solid $color-gray-4;
            position: relative;
            font-weight: normal;
        }

        th {
            border-bottom: 1px solid $color-blue;
            font-weight: normal;
        }

        td {
            > div.workflow__item--dropdown-icon {
                margin: -12px 0 -12px 0; // make up for th padding so whole sort element is clickable
                padding: 12px 0 12px 20px;
                white-space: nowrap;
                display: block;
                cursor: pointer;
                &:after {
                    content: "";
                    float: left;
                    height: 5px;
                    width: 5px;
                    top: 16px;
                    left: 0;
                    position: absolute;
                    border-color: $color-blue;
                    border-style: solid;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }
        }
    }

    // hide the content of divs on lower levels
    @for $i from 1 through 10 {
        tr[data-level="#{$i}"] {
            &,
            &:hover {
                td:nth-child(-n + #{$i}) {
                    div {
                        visibility: hidden;
                    }
                    border-bottom: 0;
                }
            }
        }
    }

    /**
    * Column types
    *
    */
    &__col-- {
        &ellipsis:not(th) {
            position: relative;
            > div {
                left: 0;
                right: 0;
                padding-right: 20px;
                position: absolute;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                > div {
                    &:empty {
                        display: none;
                    }
                    pointer-events: none;
                    background-color: white;
                    box-shadow: $box-shadow-dropdown;
                    padding: 20px;
                    left: -20px;
                    top: -8px;
                    right: 0;
                    padding-right: 20px;
                    z-index: 2;
                    position: absolute;
                    white-space: initial;
                    overflow: visible;
                    word-break: break-all;
                    text-overflow: inherit;
                }
            }
        }
        &w0 {
            width: 0%;
        }
        &w5 {
            width: 5%;
        }
        &w10 {
            width: 10%;
        }
        &w25 {
            width: 25%;
        }
        &w33 {
            width: 33%;
        }
        &w50 {
            width: 50%;
        }
        &w75 {
            width: 75%;
        }
        &w100 {
            width: 100%;
        }
        &align-r {
            div {
                text-align: right;
            }
        }
    }
}
