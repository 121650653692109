@import "@scss/theme";

.navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;

  a {
    text-decoration: none;
    color: $default-font-color;
    outline: 0;

    &.active {
      color: $color-orange;   
    }
  }

  &__button {



    margin-left: $margin-m;

    span {
      padding: 10px;
      font-weight: $menu-button-font-weight;
      font-size: $menu-button-font-size;
      line-height: 1.25em;
    }

    &:hover,
    &:focus-within {
      span, span a {
        color: $color-orange;   
      }
    }
  }

  &__button--dropdown {

    cursor: pointer;
    position: relative;

    > ul {
      visibility: hidden;
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }

    span {
      position: relative;
      padding-right: 25px;
      &:after {
        content: "";
        position: absolute;
        top: 14px;
        right: 3px;
        height: 9px;
        width: 9px;
        border: solid $default-font-color;
        border-width: 0 1px 1px 0;
        display: inline-block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    &:hover,
    &:focus-within {
      outline: 0;

      > ul {
        opacity: 1;
        visibility: visible;
      }

      span {
        &:after {
          border-color: $color-orange;
          top: 21px;
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        }
      }
    }
  }

  // drop down menu
  ul {
    display: block;
    z-index: 99;
    left: 0;
    top: 51px;
    list-style: none;
    padding: 15px;
    margin: 0 0 0 0;
    box-shadow: $box-shadow-dropdown;
    position: absolute;

    border-top: 6px solid $color-orange;
    background-color: white;

    &:before {
      position: absolute;
      top: -50px;
      left: 0;
      content: "";
      display: block;
      background-color: transparent;
      height: 50px;
      width: 100%;
    }
    > li {
      font-weight: $menu-dropdown-item-font-weight;
      font-size: $menu-dropdown-item-font-size;
      
      a {
        display: inline-block;
        padding: 12px 3px 12px 0;

        &:hover,
        &:focus-within {
          color: $color-orange;
        }
      }
    }
  }
}
