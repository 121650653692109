@import "@scss/theme";

.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0 0 $margin-s;
  display: flex;
  flex-direction: row;

  li {
    a {
      text-decoration: none;
      color: $default-font-color;

      &:after {
        content: "";
        position: relative;
        border-style: solid;
        border-color: $default-font-color;
        border-width: 1px 1px 0 0;
        display: inline-block;
        top: -2px;
        height: 4px;
        width: 4px;
        transform: rotate(45deg);
        margin: 0 15px 0 4px;
        z-index: -1; /* fix display over top menu */
      }

      &:hover {
        color: $color-orange;
        &:after {
          border-color: $color-orange;
        }
      }
    }

    &:first-child {
      a {
        cursor: default;
        color: $color-gray-1;
        &:after {
          border-color: $color-gray-1;
        }
      }
    }
  }
}
