@use "sass:map";

$default-font: "Open Sans", sans-serif;

$border-radius-default: 8px;
$border-width-default: 2px;
$border-width-thick: 8px;

$margin-xs: 10px;
$margin-s: 20px;
$margin-m: 30px;
$margin-l: 40px;
$margin-xl: 50px;
$margin-xxl: 60px;

$box-shadow-s: 0px 0px 5px rgba(38, 56, 75, 0.35);
$box-shadow-m: 0 0 10px 3px rgba(0, 0, 0, 0.1);
$box-shadow-notification: 0px 5px 10px rgba(0, 0, 0, 0.15);
$box-shadow-dropdown: 0px 5px 10px rgba(38, 56, 75, 0.25);

/*---------------------------------------------------------
COLORS
---------------------------------------------------------*/
$color-blue: #26384B;
$color-orange: #F08D17;

$color-gray-1: #808080;
$color-gray-2: #A5A5A5;
$color-gray-3: #BBBBBB;
$color-gray-4: #DEDEDE;
$color-gray-5: #EBEEEE;
$color-gray-6: #FAFAFA;

$color-accent-1: #DAE6F1;
$color-accent-2: #DEE7E0;
$color-accent-3: #F7E3CB;
$color-accent-4: #FCE3D8;
$color-accent-5: #80BFFA;

$default-font-color: $color-blue;

/*---------------------------------------------------------
Typography
---------------------------------------------------------*/
$heading-1-size: 2.4em;
$heading-1-weight: 700;

$heading-2-size: 1.65em;
$heading-2-weight: 700;

$menu-button-font-size: 1.35em;
$menu-button-font-weight: 600;

$menu-dropdown-item-font-size: 1.35em;
$menu-dropdown-item-font-weight: 400;

$button-font-size: 1em;
$button-font-weight: 600;

/*---------------------------------------------------------
VENDOR
---------------------------------------------------------*/
@import './vendor/bootstrap'