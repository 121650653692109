@import "@scss/theme";

.export-controls {
    display: flex;
    align-items: flex-start;
    gap: $margin-s;

    &__icon {
        display: inline-block;
        height: 1.4em;
        width: 10px;

        background-color: $color-blue;
        mask-image: url(img/icon-export.svg);
        mask-position: center;
        mask-repeat: no-repeat;
    }

    &__button:disabled {
        .export-controls__icon {
            background-color: $color-gray-3;
        }
    }
}

