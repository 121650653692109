@import "@scss/theme";

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 80px;
  box-shadow: $box-shadow-m;
  background: white;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  &__logo {
    flex: 0;
    > div {
      cursor: pointer;
      height: 30px;
      padding: 0 30px 0 42px;
      white-space: nowrap;
      background-image: url(./img/logo.svg);
      background-repeat: no-repeat;
      background-position: left center;
      font-weight: $heading-2-weight;
      font-size: $heading-2-size;
      line-height: 1.3em;
      white-space: nowrap;
      color: $default-font-color;
      text-decoration: none;
    }
  }

  &__navigation {
    flex: 1;
  }

  &__user {
    flex: 0;
    padding: 10px;
  }
}
