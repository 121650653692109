body {
  overflow-y: scroll; /* Show scrollbars */
  margin: 0;
  font-family: $default-font;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13.3px;
  line-height: 1.4em;
  padding-top: 80px; /* spacing for main navigation */
  padding-bottom: 100px; /* spacing for action navigation */
}

// see https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
iframe#webpack-dev-server-client-overlay{display:none!important}

button,
input,
textarea,
select,
p,
div {
  font-family: $default-font;
  font-size: 1em;
  line-height: 1.4em;
}

p {
  padding: 0;
  margin-top: 0;
  margin-bottom: 2em;
}

a {
  color: $color-accent-5;
  text-decoration: none;
  &:hover, &:active {
    text-decoration: underline;
  } 
}

* {
  box-sizing: border-box;
}
