@import "@scss/theme";

.section {
  padding-top: $margin-xl;
  padding-bottom: $margin-xl &--compact {
    padding-top: $margin-m;
    padding-bottom: $margin-m;
  }

  &--margin-t-m {
    padding-top: $margin-m;
  }
}

.section + .section {
  padding-top: 0;
}
