
@import "@scss/theme";

.input-wrapper {
    position: relative;
    box-shadow: $box-shadow-s;
    background-color: white;
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid $color-orange;
    }

    &.error {
        border:1px solid $color-orange!important;
        box-shadow: 0px 0px 5px rgba($color-orange, 0.5);
    }
    
    input, textarea {
        color: $default-font-color;
        background-color: transparent;
        width: 100%;
        border: 0;
        outline: 0;
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
    }

    textarea {
        resize: none;
        line-height: normal;
        padding: 5px 10px;
    }    
}
