@import "@scss/theme";

.action-bar {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    box-shadow: 0px 0px 5px rgba(38, 56, 75, 0.35);
    background-color: white;
    text-align: center;

    &__container {
        position: relative;
        min-height: 37px;
    }

    button {
        margin: 0 5px;
    }

    .button-direction--left {
        margin: 0;
        position: absolute;
        left: 0;
    }

    .button-direction--right {
        margin: 0;
        position: absolute;
        right: 0;
    }

    &__locked {
        line-height: 37px;
        display: inline-block;
        background-image: url(./img/icon-lock.svg);
        background-position: left calc(50% - 2px);
        background-size: 16px;
        padding-left: 22px;
        background-repeat: no-repeat;
        font-weight: 600;
        white-space: nowrap;
        text-align: left;
    }
}
