@import "@scss/theme";

.pagination {
  text-align: center;
  margin-bottom: $margin-l;

  span {
    display: inline-block;
    margin: 0 $margin-xs;
  }
}
