@import "@scss/theme";

.search-field {
    box-shadow: 0 0 0;
    background-color: $color-gray-5;
    input {
        color: $default-font-color;
    }
    button {
        color: transparent;
        border: 0;
        background: transparent;
        background-image: url(./img/icon-search.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 35px;
        overflow: hidden;
    }
}
