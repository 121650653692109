@use "sass:map";
@import "@scss/theme";

.button {
    + .button {
        margin-left: 15px;
    }

    cursor: pointer;
    transition: all 0.15s ease-in-out;
    border-radius: 25px;
    padding: 8px 20px;
    border: 1px solid transparent;
    color: $default-font-color;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    display: inline-block;
    outline: 0;

    &,
    &--primary {
        background-color: $color-gray-5;
        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
        &:disabled {
            background-color: $color-gray-5;
            color: $color-gray-3;
            border: 1px solid transparent;
            cursor: default;
        }
    }

    &--secondary {
        background-color: $color-accent-1;
        &:focus,
        &:active,
        &:hover {
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
    }

    &--positive {
        background-color: $color-accent-2;
        &:focus,
        &:active,
        &:hover {
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
    }

    &--negative {
        background-color: $color-accent-3;
        &:focus,
        &:active,
        &:hover {
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
    }

    &--warning {
        background-color: $color-accent-4;
        &:focus,
        &:active,
        &:hover {
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
    }

    &--navigation {
        background-color: white;
        border: 1px solid $color-gray-2;
        &:focus,
        &:active,
        &:hover {
            &:not(:disabled) {
                border: 1px solid $color-orange;
            }
        }
    }
}
