input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  
  transition: all 0.1s ease-in-out;
  position: relative;
  height: 20px;
  width: 20px;
  outline: none;
  background-color: white;
  border: 2px solid $color-gray-4;
  border-radius: 4px;

  &:checked {
    border: 2px solid white;
    background-color: $color-accent-5;
    &:before {
      content: "";
      display: block;
      position: relative;
      left: 3px;
      top: 3px;
      height: 10px;
      width: 10px;
      background-color: white;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
  }
}
