@import "@scss/theme";

@keyframes animPopupBackgroundAppear {
  0% {
    background-color: rgba(38, 56, 75, 0);
  }
  100% {
    background-color: rgba(38, 56, 75, 0.5);
  }
}

@keyframes animPopupAppear {
  0% {
    opacity: 0;
    top: calc(-8% + 7px);
  }
  100% {
    opacity: 1;
    top: -8%;
  }
}

.popup-wrapper {
  &--hidden {
    display: none;
  }

  animation: animPopupBackgroundAppear 0.15s forwards;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(38, 56, 75, 0.5);
  transition: all 0.15s ease-in-out;
}

.popup {
  top: calc(-8% + 7px);
  opacity: 0;
  animation: animPopupAppear 0.15s forwards;
  animation-delay: 0.15s;
  position: relative;
  border-style: solid;
  border-color: $color-accent-5;
  border-width: 4px 0 0 0;
  padding: $margin-l;
  text-align: center;
  box-shadow: $box-shadow-m;
  background-color: white;
  width: 500px;

  &__text {
    p {
      padding: $margin-xs 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 10px;
    background-image: url(./img/icon-info.svg);
    background-repeat: no-repeat;
    margin: 2px 15px 0 0;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }

  &--warning {
    border-color: $color-orange;
    .popup__icon {
      background-image: url(./img/icon-warning.svg);
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    background-image: url(./img/b-close.svg);
    background-repeat: no-repeat;
    margin: 2px 0 0 15px;
    background-size: 16px;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    border: 0;
    padding: 0;
    overflow: hidden;
    color: transparent;
  }
}
