

.text-field {
  display: flex;

  textarea {
    overflow: hidden;
    resize: none;
  }
}
