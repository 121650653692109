@import "@scss/theme";

@keyframes numSelectedAppear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.combo-box {
    position: relative;
    display: inline-block;
    box-shadow: $box-shadow-s;
    background-color: white;
    width: 100%;
    border: 0;
    outline: 0;
    border-bottom: 1px solid transparent;

    &.error {
        border:1px solid $color-orange!important;
        box-shadow: 0px 0px 5px rgba($color-orange, 0.5);
    }

    &:hover {
        &:not(.combo-box--expanded) {
            border-bottom: 1px solid $color-orange;
        }
    }

    &__num-selected {
        pointer-events: none;
        animation: numSelectedAppear 0.2s;
        position: absolute;
        right: 32px;
        top: 10px;
        display: inline-block;
        text-align: center;
        min-width: 17px;
        border-radius: 8px;
        line-height: 15px;
        color: white;
        background-color: $color-blue;
        border: 1px solid $color-blue;
    }

    &__opener,
    &__filter {
        &:before {
            content: "";
            position: absolute;
            width: 30px;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
        }
        &:after {
            content: "";
            position: absolute;
            top: 12px;
            right: 15px;
            height: 6px;
            width: 6px;
            border: solid $default-font-color;
            border-width: 0 1px 1px 0;
            display: inline-block;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            pointer-events: none;
        }
    }

    &__opener,
    &__filter {
        position: relative;

        button.combo-box__placeholder {
            color: $color-gray-1;
        }

        button,
        input {
            line-height: 36px;
            color: $default-font-color;
            padding-right: 30px;
            background: white;
            padding: 0 50px 0 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            width: 100%;
            border: 0;
            text-align: left;

            &:focus {
                outline: none;
            }
        }
    }

    &__dropdown {
        width: 100%;
        top: 38px;
        max-height: 250px;
        overflow: auto;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: $box-shadow-dropdown;
        background: white;
        position: absolute;
        z-index: 998; // should be behind action bar

        button {
            background: transparent;
            min-height: 35px;
            border: 0;
            width: 100%;
            padding: 8px;
            text-align: left;
            color: $default-font-color;
            background-color: white;
            white-space: nowrap;

            &.reset {
                color: $color-gray-1;
            }
            
            &.active {
                color: $color-orange;
            }

            &:hover,
            &:focus {
                outline: none;
                background-color: $color-gray-5;
            }
        }
    }

    &--up {
        .combo-box__dropdown {
            top: auto;
            bottom: 38px;
        }
    }

    &__noresult {
        padding: 8px;
    }

    &--collapsed {
        .combo-box__filter {
            display: none;
        }
    }
    &--expanded {
        .combo-box__opener {
            display: none;
        }

        .combo-box__opener,
        .combo-box__filter {
            &:after {
                top: 16px;
                right: 15px;
                transform: rotate(225deg);
                -webkit-transform: rotate(225deg);
            }
        }
    }

    /**
    * multi select checkbox styling
    */
    &--multi-select {
        .combo-box__dropdown {
            button {
                color: $default-font-color;
                position: relative;
                padding-left: 35px;

                &:after {
                    content: "";
                    transition: all 0.1s ease-in-out;
                    display: block;
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    outline: none;
                    background-color: white;
                    border: 2px solid $color-gray-4;
                    border-radius: 4px;
                    left: 5px;
                    top: 5px;
                }

                &.active {
                    &:after {
                        border: 2px solid white;
                        background-color: $color-accent-5;
                    }
                    &:before {
                        pointer-events: none;
                        content: "";
                        display: block;
                        z-index: 1;
                        position: absolute;
                        left: 12px;
                        top: 12px;
                        height: 10px;
                        width: 10px;
                        background-color: white;
                        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                    }
                }
            }
        }
    }
}
