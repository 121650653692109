@import "@scss/theme";

.container .container {
  padding: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    &.gutter-l {
      & > .row > div[class^="col-"] {
        padding-right: calc(var(--bs-gutter-x) * 1.0);
        padding-left: calc(var(--bs-gutter-x) * 1.0);

        &:last-child {
          padding-right: calc(var(--bs-gutter-x) * 0.5);
        }
        &:first-child {
          padding-left: calc(var(--bs-gutter-x) * 0.5);
        }
      }
    }
  }
}
