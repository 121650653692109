@import "@scss/theme";

@keyframes throbberAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes throbberAppearAnimation {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
    }
}

@keyframes throbberDisppearAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.throbber-container {
    &.throbber--secondary {
        background-color: white;
    }
}

.throbber-container {

    &--visible {
        opacity: 1;
    }
    
    &--hidden {
        animation: throbberDisppearAnimation 0.1s 0.1s forwards;
    }

    z-index: 99;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left:0;
    top:0;
    right: 0;
    bottom: 0;

    .throbber {
        display: inline-block;
        width: 35px;
        height: 35px;
    }

    .throbber:after {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid #fff;
        border-color: $color-orange transparent $color-orange transparent;
        animation: throbberAnimation 1.2s linear infinite;
    }
}
