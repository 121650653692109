@import "@scss/theme";

.button-direction {
    cursor: pointer;
    position: relative;
    border: 0;
    background-color: transparent;
    padding-right: 10px;
    padding-left: 20px;
    height: 37px;
    display: inline-block;
    color: $default-font-color;
    font-size: $button-font-size;
    font-weight: $button-font-weight;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 10px;
        background-size: 200px 112px;
        background-position: left top;
        background-image: url(./img/b-part-0.svg);
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        bottom: 0;
        width: 10px;
        background-size: 10px 112px;
        background-position: left top;
        background-image: url(./img/b-part-1.svg);
    }

    &:focus-visible {
        outline: none;
    }

    &:hover:not(:disabled), &:focus:not(:disabled) {
        &::before,
        &::after {
            background-position: left -37px;
        }
    }
    &:hover:not(:disabled) {
        color: $color-orange;
    }

    &:disabled {
        color: $color-gray-2;
        cursor: default;
        &::before,
        &::after {
            background-position: left -74px;
        }
    }

    &--right {
        padding-right: 20px;
        padding-left: 10px;
        &::before {
            transform: scaleX(-1);
            left: 10px;
            right: 0;
        }
        &::after {
            transform: scaleX(-1);
            left: 0px;
        }
    }
}
