h1 {
  font-weight: $heading-1-weight;
  font-size: $heading-1-size;
  padding: 0;
  margin-top: 0;
  line-height: 1em;
  margin-bottom: 0.9em;
}

h2, .h2 {
  font-weight: $heading-2-weight;
  font-size: $heading-2-size;
  padding: 0;
  margin-top: 0;
  line-height: 1em;
  margin-bottom: 0.9em;

  &.alt {
    color: $color-gray-1;
  }
}