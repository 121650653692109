@import "@scss/theme";

.listview--norwap {
    white-space: nowrap;
}

.listview {

    min-height: 300px;
    position: relative;
    margin-bottom: $margin-l;

    &--sticky-header {
        thead {
            position: sticky;
            top: 80px;
            z-index: 1;
            background: white;
        }
    }

    &__checkbox-col {
        position: relative;
        z-index: 1;
        width: 35px;
        min-width: 35px;
    }

    &__link-col:not(th) {
        position: initial !important; // sorry :-)

        &::after {
            content: "";
            display: block;
            height: 10px;
            width: 10px;
            top: 2px;
            position: relative;
            content: "";
            border: solid $default-font-color;
            border-width: 0 1px 1px 0;
            display: inline-block;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }

    tr:hover {
        .listview__link-col:not(th) {
            &::after {
                border: solid $color-orange;
                border-width: 0 1px 1px 0;
            }
        }
    }

    table {
        padding: 0;
        margin: 0;
        width: 100%;
        border-spacing: 0;

        tr {
            position: relative;

            &:hover,
            &.listview__row--checked {
                td {
                    background-color: $color-gray-6;
                }
            }
        }

        td,
        th {
            margin: 0;
            padding: 12px 20px 12px 0;
            text-align: left;
            vertical-align: top;
            border-bottom: 1px solid $color-gray-4;
            position: relative;

            &.listview__checkbox-col {
                padding: 8px 0 0 0;
            }
        }

        th {
            border-bottom: 1px solid $color-blue;
            font-weight: normal;

            >div.listview__sort {
                margin: -12px 0 -12px 0; // make up for th padding so whole sort element is clickable
                padding: 12px 20px 12px 0;
                white-space: nowrap;
                display: block;
                cursor: pointer;

                &:after {
                    content: "";
                    float: right;
                    height: 5px;
                    width: 5px;
                    top: 16px;
                    right: 23px;
                    position: absolute;
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }

                &.desc,
                &.asc {
                    color: $color-orange;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 20px;
                        bottom: -1px;
                        border-bottom: 2px solid $color-orange;
                    }

                    &:after {
                        border-color: $color-orange;
                    }
                }

                &.asc {
                    &:after {
                        top: 20px;
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(-135deg);
                    }
                }

                &:hover {
                    color: $color-orange;

                    &:after {
                        border-color: $color-orange;
                    }
                }
            }
        }
    }

    &__col-- {
        &ellipsis:not(th) {
            position: relative;

            >div {
                left: 0;
                right: 0;
                padding-right: 20px;
                position: absolute;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {

                >div {

                    &:empty {
                        display: none;
                    }

                    pointer-events: none;
                    background-color: white;
                    box-shadow: $box-shadow-dropdown;
                    padding: 20px;
                    left: -20px;
                    top: -8px;
                    right: 0;
                    padding-right: 20px;
                    z-index: 2;
                    position: absolute;
                    white-space: initial;
                    overflow: visible;
                    word-break: break-all;
                    text-overflow: inherit;
                }
            }
        }

        &w0 {
            width: 0%;
        }

        &w15 {
            width: 15%;
        }

        &w25 {
            width: 25%;
        }

        &w33 {
            width: 33%;
        }

        &w50 {
            width: 50%;
        }

        &w75 {
            width: 75%;
        }

        &w100 {
            width: 100%;
        }

        // MIN WIDTH
        &mw-115 {
            min-width: 115px;
        }
    }

    &--clickable {
        tr {
            &:hover {
                td {
                    cursor: pointer;
                    border-bottom: 1px solid $color-orange;
                }
            }
        }
    }

    &__no-result {
        min-height: 50vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
            text-align: center;
            min-width: none;
            width: 25%;
            border: 1px solid $color-gray-4;
            padding: $margin-l;
            font-size: 1.25em;
            font-weight: 700;
            color: $color-gray-1;
        }
    }
}